import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import '../helpers/graphql-image-fragments';

export default ({ data, location }) => {
  return (
    <Layout location={location} title={data.site.siteMetadata.title} page={'coloc'}>
      <Img style={{height: '125vh'}} fluid={data.amsAurora.childImageSharp.fluid} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    amsAurora: file(relativePath: { eq: "photos/ams-aurora.jpg" }) {
      ...maxWidthImage
    }
  }
`;
